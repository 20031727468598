import { RecipeVariants } from '@/style/utils/createRecipeObj';
import { createUseRecipeHook } from '@/style/utils/createUseRecipeHook';
import { root } from './Txt.css';
import classNames from 'classnames';

export const useTxtRecipe = createUseRecipeHook(root);
export type TxtStyleProps = RecipeVariants<typeof root>;

export type UseTxtStyleProps = TxtStyleProps & {
  className?: string;
};

const headingClasses: TxtStyleProps['variant'][] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export const useTxtStyle = <T extends UseTxtStyleProps>(props: T) => {
  // const { fonts } = useBrand();
  const { className, ...rest } = useTxtRecipe(props);

  const isHeading = !!props.variant && headingClasses.includes(props.variant);

  const fontClassName = isHeading ? 'heading-txt' : '';

  return {
    className: classNames(className, fontClassName),
    ...rest,
  };
};
