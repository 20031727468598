import { SVG_SIZE, SVGType } from '@/lib/constants';
import React from 'react';

interface Arrow extends SVGType, React.HTMLAttributes<SVGElement> {
  stroke?: string;
}

export default function Arrow({ size, stroke, className }: Arrow) {
  return (
    <svg
      className={className}
      style={{
        height: SVG_SIZE[size].height,
        width: SVG_SIZE[size].width,
        transition: '200ms ease all',
      }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        stroke={stroke}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4697 4.46967C13.7626 4.17678 14.2374 4.17678 14.5303 4.46967L21.5303 11.4697C21.671 11.6103 21.75 11.8011 21.75 12C21.75 12.1989 21.671 12.3897 21.5303 12.5303L14.5303 19.5303C14.2374 19.8232 13.7626 19.8232 13.4697 19.5303C13.1768 19.2374 13.1768 18.7626 13.4697 18.4697L19.1893 12.75L3 12.75C2.58579 12.75 2.25 12.4142 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25L19.1893 11.25L13.4697 5.53033C13.1768 5.23744 13.1768 4.76256 13.4697 4.46967Z"
      />
    </svg>
  );
}
