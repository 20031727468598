import { ImageEntry, ImageTransform, ImageTransformType } from '@/lib/parse/images';
import { useResponsiveValue } from '@/style/utils/useBreakpoints';
import { Breakpoint } from '@/style/utils/breakpoints';
import { ImageLoader } from 'next/image';

export type ResponsiveImageTransform =
  | { [P in Breakpoint]?: ImageTransformType }
  | ImageTransformType;

export const useImageTransform = <T extends ImageEntry>(
  image?: Partial<T>,
  transform?: ResponsiveImageTransform
): (ImageTransform & { loader?: ImageLoader }) | undefined => {
  // Get default values

  const currentTransform = useResponsiveValue(transform);

  if (!image) return undefined;

  const { src: url = '', width = 100, height = 100 } = image;

  const loader: ImageLoader | undefined =
    currentTransform === 'original' ? ({ src }) => src : undefined;

  const maybeTransformValue = currentTransform ? (image.transforms ?? {})[currentTransform] : null;

  return (
    maybeTransformValue ?? {
      url,
      width,
      height,
      loader,
    }
  );
};
