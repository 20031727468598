/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment accordionBlock on blocks_accordion_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    children {\n      ...accordionItemBlock\n    }\n    linkCta {\n      ...linkField\n    }\n  }\n": types.AccordionBlockFragmentDoc,
    "\n  fragment accordionItemBlock on blocks_accordionItem_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentDefault\n  }\n": types.AccordionItemBlockFragmentDoc,
    "\n  fragment featuredNewsBlock on blocks_featuredNews_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    articleMultiple {\n      ...articleCard\n    }\n  }\n": types.FeaturedNewsBlockFragmentDoc,
    "\n  fragment heroStatementBlock on blocks_heroStatement_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentDefault\n    bgImage: imageSingle {\n      ...image\n    }\n    colorTheme {\n      label\n    }\n  }\n": types.HeroStatementBlockFragmentDoc,
    "\n  fragment ctaBlock on blocks_ctaBlock_BlockType {\n    id\n    __typename\n    enabled\n    ctaBlock {\n      ...ctaEntry\n    }\n  }\n": types.CtaBlockFragmentDoc,
    "\n  fragment imageBlock on blocks_image_BlockType {\n    id\n    __typename\n    enabled\n    imageSingle {\n      ...image\n    }\n    imageWidth\n    label\n  }\n": types.ImageBlockFragmentDoc,
    "\n  fragment iconGridBlock on blocks_iconGrid_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    children {\n      ...iconCardBlock\n    }\n\n    # Customisation\n    colorTheme {\n      label\n    }\n  }\n": types.IconGridBlockFragmentDoc,
    "\n  fragment iconCardBlock on blocks_iconCard_BlockType {\n    id\n    __typename\n    enabled\n    icon\n    heading\n    content: htmlContentSimple\n    linkCta {\n      ...linkField\n    }\n  }\n": types.IconCardBlockFragmentDoc,
    "\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    children {\n      ...imageAndTextChildBlock\n    } \n    colorTheme {\n      label\n    }\n  }\n": types.ImageAndTextBlockFragmentDoc,
    "\nfragment imageAndTextChildBlock on blocks_imageAndTextChild_BlockType {\n  id\n  __typename\n  enabled\n  content: htmlContentDefault\n    linkCta {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    imageType\n    flipAlignment\n}\n": types.ImageAndTextChildBlockFragmentDoc,
    "\n  fragment jobListingsBlock on blocks_jobListings_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    children {\n      ...jobListingBlock\n    }\n  }\n": types.JobListingsBlockFragmentDoc,
    "\n  fragment jobListingBlock on blocks_jobListing_BlockType {\n    id\n    __typename\n    enabled\n    job: label\n    content: htmlContentDefault\n    linkCta {\n      ...linkField\n    }\n  }\n": types.JobListingBlockFragmentDoc,
    "\n  fragment leadershipTeamBlock on blocks_leadershipTeam_BlockType {\n    id\n    __typename\n    enabled\n    profileMultiple {\n      ...profileEntry\n    }\n  }\n": types.LeadershipTeamBlockFragmentDoc,
    "\n  fragment richTextBlock on blocks_richText_BlockType {\n    id\n    __typename\n    enabled\n    content: htmlContentDefault\n    linkCta {\n      ...linkField\n    }\n    colorTheme {\n      label\n    }\n  }\n": types.RichTextBlockFragmentDoc,
    "\n  fragment statsBlock on blocks_stats_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    linkCta {\n      ...linkField\n    }\n    children {\n      ...statBlock\n    }\n  }\n": types.StatsBlockFragmentDoc,
    "\n  fragment statBlock on blocks_stat_BlockType {\n    id\n    __typename\n    enabled\n    number: label\n    content: heading\n    linkCta {\n      ...linkField\n    }\n  }\n": types.StatBlockFragmentDoc,
    "\n  fragment testimonialBlock on blocks_testimonial_BlockType {\n    id\n    __typename\n    enabled\n    quote: heading\n    firstName\n    lastName\n    jobPosition: label\n    colorTheme {\n      label\n    }\n  }\n": types.TestimonialBlockFragmentDoc,
    "\n  fragment tableBlock on blocks_table_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    label\n    table {\n      rows\n      table\n      columns {\n        align\n        heading\n        width\n      }\n    }\n    colorTheme {\n      label\n    }\n  }\n": types.TableBlockFragmentDoc,
    "\n  fragment typeformBlock on blocks_typeform_BlockType {\n    id\n    __typename\n    enabled\n    typeformUrl: label\n  }\n": types.TypeformBlockFragmentDoc,
    "\n  fragment videoBlock on blocks_video_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    videoUrl: websiteUrl\n    colorTheme {\n      label\n    }\n  }\n": types.VideoBlockFragmentDoc,
    "\n  fragment curatedArticlesBlock on blocks_curatedArticles_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    articleMultiple {\n      ...articleCard\n    }\n  }\n": types.CuratedArticlesBlockFragmentDoc,
    "\n  fragment quoteBlock on blocks_quote_BlockType {\n    id\n    __typename\n    content: heading\n    author: label\n    lightswitch\n  }\n": types.QuoteBlockFragmentDoc,
    "\n  fragment mediaGalleryBlock on blocks_mediaGallery_BlockType {\n    id\n    __typename\n    heading\n    imageMultiple {\n      ...image\n    }\n  }\n": types.MediaGalleryBlockFragmentDoc,
    "\n  fragment pdfDownloadBlock on blocks_pdfDownload_BlockType {\n    id\n    __typename\n    documentMultiple {\n      ...document\n    }\n  }\n": types.PdfDownloadBlockFragmentDoc,
    "\n  fragment blocks on blocks_NeoField {\n    ...accordionBlock\n    ...accordionItemBlock\n    ...featuredNewsBlock\n    ...heroStatementBlock\n    ...ctaBlock\n    ...imageBlock\n    ...mediaGalleryBlock\n    ...iconGridBlock\n    ...iconCardBlock\n    ...imageAndTextBlock\n    ...jobListingsBlock\n    ...jobListingBlock\n    ...leadershipTeamBlock\n    ...richTextBlock\n    ...statsBlock\n    ...statBlock\n    ...testimonialBlock\n    ...tableBlock\n    ...typeformBlock\n    ...videoBlock\n    ...curatedArticlesBlock\n    ...quoteBlock\n    ...pdfDownloadBlock\n  }\n": types.BlocksFragmentDoc,
    "\n  fragment author on UserInterface {\n      __typename\n      id\n      displayName: fullName\n      firstName\n      lastName\n  }\n": types.AuthorFragmentDoc,
    "\n  fragment documentBase on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n  }\n": types.DocumentBaseFragmentDoc,
    "\n  fragment document on AssetInterface {\n    ...documentBase\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n": types.DocumentFragmentDoc,
    "\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    sectionHandle\n    parent {\n      title\n    }\n    title\n    postDate @formatDateTime(format: \"j M, Y\")\n  }\n": types.EntryBaseFragmentDoc,
    "\n  fragment pageStandardEntry on standard_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    headerLogo: imageSingle {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n    headerType\n    colorTheme {\n      label\n    }\n  }\n": types.PageStandardEntryFragmentDoc,
    "\n  fragment pageOverviewEntry on overview_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n    headerLogo: imageSingle {\n      ...image\n    }\n    headerType\n    colorTheme {\n      label\n    }\n  }\n": types.PageOverviewEntryFragmentDoc,
    "\n  fragment articleIndexEntry on articleIndex_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n  }\n": types.ArticleIndexEntryFragmentDoc,
    "\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n  }\n": types.ArticleEntryFragmentDoc,
    "\n  fragment ctaEntry on ctaBlock_Entry {\n    ...entryBase\n    imageSingle {\n      ...image\n    }\n    heading\n    content: htmlContentSimple\n    linkCta {\n      ...linkField\n    }\n    colorTheme {\n      label\n    }\n  }\n": types.CtaEntryFragmentDoc,
    "\n  fragment profileEntry on profile_Entry {\n    ...entryBase\n    firstName\n    lastName\n    imageSingle {\n      ...image\n    }\n    jobPosition: label\n    description: htmlContentSimple\n    linkedinUrl: websiteUrl\n  }\n": types.ProfileEntryFragmentDoc,
    "\n  fragment homeEntry on home_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n    headerType\n    colorTheme {\n      label\n    }\n  }\n": types.HomeEntryFragmentDoc,
    "\n  fragment partnerMultiple on partnerMultiple_Entry {\n      ...entryBase\n      linkCta {\n        ...linkField\n      }\n      logo {\n        ...image\n      }\n  }\n": types.PartnerMultipleFragmentDoc,
    "\n  fragment entries on EntryInterface {\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...articleIndexEntry\n    ...articleEntry\n    ...ctaEntry\n    ...profileEntry\n    ...homeEntry\n    ...partnerMultiple\n  }\n": types.EntriesFragmentDoc,
    "\n  fragment articleCard on article_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    bannerImage {\n      ...image\n    }\n  }\n": types.ArticleCardFragmentDoc,
    "\n  fragment entryCards on EntryInterface {\n    ...articleCard\n  }\n": types.EntryCardsFragmentDoc,
    "\n    fragment partnersGlobal on partners_GlobalSet {\n        __typename\n        id\n        enabled\n        partnerMultiple {\n            ...partnerMultiple\n        }\n    }\n": types.PartnersGlobalFragmentDoc,
    "\n    fragment linkedinUrlGlobal on linkedinUrl_GlobalSet {\n        __typename\n        id\n        enabled\n        websiteUrl\n    }\n": types.LinkedinUrlGlobalFragmentDoc,
    "\n    fragment globals on GlobalSetInterface {\n        ...partnersGlobal\n        ...linkedinUrlGlobal\n    }\n": types.GlobalsFragmentDoc,
    "\n  fragment imageSize_original on AssetInterface {\n    original_url: url\n    original_height: width\n    original_width: height\n  }\n": types.ImageSize_OriginalFragmentDoc,
    "\n  fragment imageSize_bannerCrop on AssetInterface {\n    bannerCrop_url: url @transform(transform: \"bannerCrop\")\n    bannerCrop_width: width @transform(transform: \"bannerCrop\")\n    bannerCrop_height: height @transform(transform: \"bannerCrop\")\n  }\n": types.ImageSize_BannerCropFragmentDoc,
    "\n  fragment imageSize_fullFit on AssetInterface {\n    fullFit_url: url @transform(transform: \"fullFit\")\n    fullFit_width: width @transform(transform: \"fullFit\")\n    fullFit_height: height @transform(transform: \"fullFit\")\n  }\n": types.ImageSize_FullFitFragmentDoc,
    "\n  fragment imageSize_landscapeSmCrop on AssetInterface {\n    landscapeSmCrop_url: url @transform(transform: \"landscapeSmCrop\")\n    landscapeSmCrop_width: width @transform(transform: \"landscapeSmCrop\")\n    landscapeSmCrop_height: height @transform(transform: \"landscapeSmCrop\")\n  }\n": types.ImageSize_LandscapeSmCropFragmentDoc,
    "\n  fragment imageSize_landscapeMdCrop on AssetInterface {\n    landscapeMdCrop_url: url @transform(transform: \"landscapeMdCrop\")\n    landscapeMdCrop_width: width @transform(transform: \"landscapeMdCrop\")\n    landscapeMdCrop_height: height @transform(transform: \"landscapeMdCrop\")\n  }\n": types.ImageSize_LandscapeMdCropFragmentDoc,
    "\n  fragment imageSize_landscapeLgCrop on AssetInterface {\n    landscapeLgCrop_url: url @transform(transform: \"landscapeLgCrop\")\n    landscapeLgCrop_width: width @transform(transform: \"landscapeLgCrop\")\n    landscapeLgCrop_height: height @transform(transform: \"landscapeLgCrop\")\n  }\n": types.ImageSize_LandscapeLgCropFragmentDoc,
    "\n  fragment imageSize_squareSmCrop on AssetInterface {\n    squareSmCrop_url: url @transform(transform: \"squareSmCrop\")\n    squareSmCrop_width: width @transform(transform: \"squareSmCrop\")\n    squareSmCrop_height: height @transform(transform: \"squareSmCrop\")\n  }\n": types.ImageSize_SquareSmCropFragmentDoc,
    "\n  fragment imageSize_squareMdCrop on AssetInterface {\n    squareMdCrop_url: url @transform(transform: \"squareMdCrop\")\n    squareMdCrop_width: width @transform(transform: \"squareMdCrop\")\n    squareMdCrop_height: height @transform(transform: \"squareMdCrop\")\n  }\n": types.ImageSize_SquareMdCropFragmentDoc,
    "\n  fragment imageSize_squareLgCrop on AssetInterface {\n    squareLgCrop_url: url @transform(transform: \"squareLgCrop\")\n    squareLgCrop_width: width @transform(transform: \"squareLgCrop\")\n    squareLgCrop_height: height @transform(transform: \"squareLgCrop\")\n  }\n": types.ImageSize_SquareLgCropFragmentDoc,
    "\n  fragment imageSize_all on AssetInterface {\n    ...imageSize_original\n    ...imageSize_bannerCrop\n    ...imageSize_fullFit\n    ...imageSize_landscapeLgCrop\n    ...imageSize_landscapeMdCrop\n    ...imageSize_landscapeSmCrop\n    ...imageSize_squareLgCrop\n    ...imageSize_squareMdCrop\n    ...imageSize_squareSmCrop\n  }\n": types.ImageSize_AllFragmentDoc,
    "\n  fragment imageSize_common on AssetInterface {\n    ...imageSize_original\n    ...imageSize_bannerCrop\n    ...imageSize_fullFit\n    ...imageSize_landscapeLgCrop\n    ...imageSize_landscapeMdCrop\n    ...imageSize_landscapeSmCrop\n    ...imageSize_squareLgCrop\n    ...imageSize_squareMdCrop\n    ...imageSize_squareSmCrop\n  }\n": types.ImageSize_CommonFragmentDoc,
    "\n  fragment imageBase on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    url @transform(transform: \"fullFit\")\n    height @transform(transform: \"fullFit\")\n    width @transform(transform: \"fullFit\")\n  }\n": types.ImageBaseFragmentDoc,
    "\n  fragment image on AssetInterface {\n    ...imageBase\n    ...imageSize_common\n  }\n": types.ImageFragmentDoc,
    "\n  fragment linkFieldUrl on linkCta_ExternalLink_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldUrlFragmentDoc,
    "\n  fragment linkFieldEntry on linkCta_InternalLink_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldEntryFragmentDoc,
    "\n  fragment linkFieldEmail on linkCta_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldEmailFragmentDoc,
    "\n  fragment linkFieldPhone on linkCta_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldPhoneFragmentDoc,
    "\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n": types.LinkFieldFragmentDoc,
    "\n  fragment navigationBase on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n        uri\n        language\n    } \n  }\n": types.NavigationBaseFragmentDoc,
    "\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n": types.SeoMetaLinksFragmentDoc,
    "\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n": types.SeoMetaTagsFragmentDoc,
    "\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n": types.SeoTitleFragmentDoc,
    "\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n": types.SeoScriptsFragmentDoc,
    "\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n": types.SeoJsonLdFragmentDoc,
    "\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n": types.SeoSiteVarsFragmentDoc,
    "\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      ...seoTitle\n      ...seoJsonLd\n      ...seoMetaLinks\n      ...seoMetaTags\n      ...seoScripts\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      ...seoSiteVars\n    }\n  }\n": types.EntrySeoFragmentDoc,
    "\n  query allUris($section: [String], $type: [String], $orderBy: String) {\n    entries(section: $section, type: $type, orderBy: $orderBy) {\n      id\n      uri\n      slug\n    }\n  }\n": types.AllUrisDocument,
    "\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy) {\n      ...entries\n    }\n  }\n": types.ContentByUriDocument,
    "\n  query entryExists($uri: [String], $slug: [String], $id: [QueryArgument]) {\n    entry(uri: $uri, id: $id, slug: $slug, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n": types.EntryExistsDocument,
    "\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $offset: Int\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, offset: $offset) {\n      ...entries\n    }\n  }\n": types.ContentByTypeDocument,
    "\n  query searchEntries(\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput]\n    ) {\n    entries(\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...entries\n    }\n    entryCount(\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n    )\n  }\n": types.SearchEntriesDocument,
    "\n  query globals($site: [String] = \"default\") {\n    globalSets(site: $site) {\n      ...globals\n    }\n  }\n": types.GlobalsDocument,
    "\n  query navMenus {\n    primaryNavigationNodes: navigationNodes(navHandle: \"primaryNavigation\") {\n      ...navigationBase\n    }\n    footerNavigationNodes: navigationNodes(navHandle: \"footerNavigation\") {\n      ...navigationBase\n    }\n    extrasNavigationNodes: navigationNodes(navHandle: \"extrasNavigation\") {\n      ...navigationBase\n    }\n    specialNavigationNodes: navigationNodes(navHandle: \"specialNavigation\") {\n      ...navigationBase\n    }\n    ctaNavigationNodes: navigationNodes(navHandle: \"ctaNavigation\") {\n      ...navigationBase\n    }\n  }\n": types.NavMenusDocument,
    "\n  query uriRedirect($site: String = \"default\", $uri: String) {\n    retour(site: $site, uri: $uri) {\n      redirectDestUrl\n      redirectHttpCode\n    }\n  }\n": types.UriRedirectDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accordionBlock on blocks_accordion_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    children {\n      ...accordionItemBlock\n    }\n    linkCta {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment accordionBlock on blocks_accordion_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    children {\n      ...accordionItemBlock\n    }\n    linkCta {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accordionItemBlock on blocks_accordionItem_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentDefault\n  }\n"): (typeof documents)["\n  fragment accordionItemBlock on blocks_accordionItem_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentDefault\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment featuredNewsBlock on blocks_featuredNews_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"): (typeof documents)["\n  fragment featuredNewsBlock on blocks_featuredNews_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment heroStatementBlock on blocks_heroStatement_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentDefault\n    bgImage: imageSingle {\n      ...image\n    }\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment heroStatementBlock on blocks_heroStatement_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentDefault\n    bgImage: imageSingle {\n      ...image\n    }\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ctaBlock on blocks_ctaBlock_BlockType {\n    id\n    __typename\n    enabled\n    ctaBlock {\n      ...ctaEntry\n    }\n  }\n"): (typeof documents)["\n  fragment ctaBlock on blocks_ctaBlock_BlockType {\n    id\n    __typename\n    enabled\n    ctaBlock {\n      ...ctaEntry\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageBlock on blocks_image_BlockType {\n    id\n    __typename\n    enabled\n    imageSingle {\n      ...image\n    }\n    imageWidth\n    label\n  }\n"): (typeof documents)["\n  fragment imageBlock on blocks_image_BlockType {\n    id\n    __typename\n    enabled\n    imageSingle {\n      ...image\n    }\n    imageWidth\n    label\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment iconGridBlock on blocks_iconGrid_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    children {\n      ...iconCardBlock\n    }\n\n    # Customisation\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment iconGridBlock on blocks_iconGrid_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    children {\n      ...iconCardBlock\n    }\n\n    # Customisation\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment iconCardBlock on blocks_iconCard_BlockType {\n    id\n    __typename\n    enabled\n    icon\n    heading\n    content: htmlContentSimple\n    linkCta {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment iconCardBlock on blocks_iconCard_BlockType {\n    id\n    __typename\n    enabled\n    icon\n    heading\n    content: htmlContentSimple\n    linkCta {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    children {\n      ...imageAndTextChildBlock\n    } \n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    children {\n      ...imageAndTextChildBlock\n    } \n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment imageAndTextChildBlock on blocks_imageAndTextChild_BlockType {\n  id\n  __typename\n  enabled\n  content: htmlContentDefault\n    linkCta {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    imageType\n    flipAlignment\n}\n"): (typeof documents)["\nfragment imageAndTextChildBlock on blocks_imageAndTextChild_BlockType {\n  id\n  __typename\n  enabled\n  content: htmlContentDefault\n    linkCta {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    imageType\n    flipAlignment\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment jobListingsBlock on blocks_jobListings_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    children {\n      ...jobListingBlock\n    }\n  }\n"): (typeof documents)["\n  fragment jobListingsBlock on blocks_jobListings_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    children {\n      ...jobListingBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment jobListingBlock on blocks_jobListing_BlockType {\n    id\n    __typename\n    enabled\n    job: label\n    content: htmlContentDefault\n    linkCta {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment jobListingBlock on blocks_jobListing_BlockType {\n    id\n    __typename\n    enabled\n    job: label\n    content: htmlContentDefault\n    linkCta {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment leadershipTeamBlock on blocks_leadershipTeam_BlockType {\n    id\n    __typename\n    enabled\n    profileMultiple {\n      ...profileEntry\n    }\n  }\n"): (typeof documents)["\n  fragment leadershipTeamBlock on blocks_leadershipTeam_BlockType {\n    id\n    __typename\n    enabled\n    profileMultiple {\n      ...profileEntry\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment richTextBlock on blocks_richText_BlockType {\n    id\n    __typename\n    enabled\n    content: htmlContentDefault\n    linkCta {\n      ...linkField\n    }\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment richTextBlock on blocks_richText_BlockType {\n    id\n    __typename\n    enabled\n    content: htmlContentDefault\n    linkCta {\n      ...linkField\n    }\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment statsBlock on blocks_stats_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    linkCta {\n      ...linkField\n    }\n    children {\n      ...statBlock\n    }\n  }\n"): (typeof documents)["\n  fragment statsBlock on blocks_stats_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    linkCta {\n      ...linkField\n    }\n    children {\n      ...statBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment statBlock on blocks_stat_BlockType {\n    id\n    __typename\n    enabled\n    number: label\n    content: heading\n    linkCta {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment statBlock on blocks_stat_BlockType {\n    id\n    __typename\n    enabled\n    number: label\n    content: heading\n    linkCta {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment testimonialBlock on blocks_testimonial_BlockType {\n    id\n    __typename\n    enabled\n    quote: heading\n    firstName\n    lastName\n    jobPosition: label\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment testimonialBlock on blocks_testimonial_BlockType {\n    id\n    __typename\n    enabled\n    quote: heading\n    firstName\n    lastName\n    jobPosition: label\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment tableBlock on blocks_table_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    label\n    table {\n      rows\n      table\n      columns {\n        align\n        heading\n        width\n      }\n    }\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment tableBlock on blocks_table_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    content: htmlContentSimple\n    label\n    table {\n      rows\n      table\n      columns {\n        align\n        heading\n        width\n      }\n    }\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment typeformBlock on blocks_typeform_BlockType {\n    id\n    __typename\n    enabled\n    typeformUrl: label\n  }\n"): (typeof documents)["\n  fragment typeformBlock on blocks_typeform_BlockType {\n    id\n    __typename\n    enabled\n    typeformUrl: label\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment videoBlock on blocks_video_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    videoUrl: websiteUrl\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment videoBlock on blocks_video_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    videoUrl: websiteUrl\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment curatedArticlesBlock on blocks_curatedArticles_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"): (typeof documents)["\n  fragment curatedArticlesBlock on blocks_curatedArticles_BlockType {\n    id\n    __typename\n    enabled\n    heading\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment quoteBlock on blocks_quote_BlockType {\n    id\n    __typename\n    content: heading\n    author: label\n    lightswitch\n  }\n"): (typeof documents)["\n  fragment quoteBlock on blocks_quote_BlockType {\n    id\n    __typename\n    content: heading\n    author: label\n    lightswitch\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment mediaGalleryBlock on blocks_mediaGallery_BlockType {\n    id\n    __typename\n    heading\n    imageMultiple {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment mediaGalleryBlock on blocks_mediaGallery_BlockType {\n    id\n    __typename\n    heading\n    imageMultiple {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pdfDownloadBlock on blocks_pdfDownload_BlockType {\n    id\n    __typename\n    documentMultiple {\n      ...document\n    }\n  }\n"): (typeof documents)["\n  fragment pdfDownloadBlock on blocks_pdfDownload_BlockType {\n    id\n    __typename\n    documentMultiple {\n      ...document\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment blocks on blocks_NeoField {\n    ...accordionBlock\n    ...accordionItemBlock\n    ...featuredNewsBlock\n    ...heroStatementBlock\n    ...ctaBlock\n    ...imageBlock\n    ...mediaGalleryBlock\n    ...iconGridBlock\n    ...iconCardBlock\n    ...imageAndTextBlock\n    ...jobListingsBlock\n    ...jobListingBlock\n    ...leadershipTeamBlock\n    ...richTextBlock\n    ...statsBlock\n    ...statBlock\n    ...testimonialBlock\n    ...tableBlock\n    ...typeformBlock\n    ...videoBlock\n    ...curatedArticlesBlock\n    ...quoteBlock\n    ...pdfDownloadBlock\n  }\n"): (typeof documents)["\n  fragment blocks on blocks_NeoField {\n    ...accordionBlock\n    ...accordionItemBlock\n    ...featuredNewsBlock\n    ...heroStatementBlock\n    ...ctaBlock\n    ...imageBlock\n    ...mediaGalleryBlock\n    ...iconGridBlock\n    ...iconCardBlock\n    ...imageAndTextBlock\n    ...jobListingsBlock\n    ...jobListingBlock\n    ...leadershipTeamBlock\n    ...richTextBlock\n    ...statsBlock\n    ...statBlock\n    ...testimonialBlock\n    ...tableBlock\n    ...typeformBlock\n    ...videoBlock\n    ...curatedArticlesBlock\n    ...quoteBlock\n    ...pdfDownloadBlock\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment author on UserInterface {\n      __typename\n      id\n      displayName: fullName\n      firstName\n      lastName\n  }\n"): (typeof documents)["\n  fragment author on UserInterface {\n      __typename\n      id\n      displayName: fullName\n      firstName\n      lastName\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment documentBase on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n  }\n"): (typeof documents)["\n  fragment documentBase on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment document on AssetInterface {\n    ...documentBase\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n"): (typeof documents)["\n  fragment document on AssetInterface {\n    ...documentBase\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    sectionHandle\n    parent {\n      title\n    }\n    title\n    postDate @formatDateTime(format: \"j M, Y\")\n  }\n"): (typeof documents)["\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    sectionHandle\n    parent {\n      title\n    }\n    title\n    postDate @formatDateTime(format: \"j M, Y\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageStandardEntry on standard_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    headerLogo: imageSingle {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n    headerType\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment pageStandardEntry on standard_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    headerLogo: imageSingle {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n    headerType\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageOverviewEntry on overview_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n    headerLogo: imageSingle {\n      ...image\n    }\n    headerType\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment pageOverviewEntry on overview_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n    headerLogo: imageSingle {\n      ...image\n    }\n    headerType\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleIndexEntry on articleIndex_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment articleIndexEntry on articleIndex_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ctaEntry on ctaBlock_Entry {\n    ...entryBase\n    imageSingle {\n      ...image\n    }\n    heading\n    content: htmlContentSimple\n    linkCta {\n      ...linkField\n    }\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment ctaEntry on ctaBlock_Entry {\n    ...entryBase\n    imageSingle {\n      ...image\n    }\n    heading\n    content: htmlContentSimple\n    linkCta {\n      ...linkField\n    }\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment profileEntry on profile_Entry {\n    ...entryBase\n    firstName\n    lastName\n    imageSingle {\n      ...image\n    }\n    jobPosition: label\n    description: htmlContentSimple\n    linkedinUrl: websiteUrl\n  }\n"): (typeof documents)["\n  fragment profileEntry on profile_Entry {\n    ...entryBase\n    firstName\n    lastName\n    imageSingle {\n      ...image\n    }\n    jobPosition: label\n    description: htmlContentSimple\n    linkedinUrl: websiteUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment homeEntry on home_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n    headerType\n    colorTheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment homeEntry on home_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    blocks {\n      ...blocks\n    }\n    headerType\n    colorTheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment partnerMultiple on partnerMultiple_Entry {\n      ...entryBase\n      linkCta {\n        ...linkField\n      }\n      logo {\n        ...image\n      }\n  }\n"): (typeof documents)["\n  fragment partnerMultiple on partnerMultiple_Entry {\n      ...entryBase\n      linkCta {\n        ...linkField\n      }\n      logo {\n        ...image\n      }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entries on EntryInterface {\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...articleIndexEntry\n    ...articleEntry\n    ...ctaEntry\n    ...profileEntry\n    ...homeEntry\n    ...partnerMultiple\n  }\n"): (typeof documents)["\n  fragment entries on EntryInterface {\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...articleIndexEntry\n    ...articleEntry\n    ...ctaEntry\n    ...profileEntry\n    ...homeEntry\n    ...partnerMultiple\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleCard on article_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    bannerImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment articleCard on article_Entry {\n    ...entryBase\n    ...entrySeo\n    pageTitle\n    summary: entrySummary\n    featuredImage: entryImage {\n      ...image\n    }\n    bannerImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryCards on EntryInterface {\n    ...articleCard\n  }\n"): (typeof documents)["\n  fragment entryCards on EntryInterface {\n    ...articleCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment partnersGlobal on partners_GlobalSet {\n        __typename\n        id\n        enabled\n        partnerMultiple {\n            ...partnerMultiple\n        }\n    }\n"): (typeof documents)["\n    fragment partnersGlobal on partners_GlobalSet {\n        __typename\n        id\n        enabled\n        partnerMultiple {\n            ...partnerMultiple\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment linkedinUrlGlobal on linkedinUrl_GlobalSet {\n        __typename\n        id\n        enabled\n        websiteUrl\n    }\n"): (typeof documents)["\n    fragment linkedinUrlGlobal on linkedinUrl_GlobalSet {\n        __typename\n        id\n        enabled\n        websiteUrl\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment globals on GlobalSetInterface {\n        ...partnersGlobal\n        ...linkedinUrlGlobal\n    }\n"): (typeof documents)["\n    fragment globals on GlobalSetInterface {\n        ...partnersGlobal\n        ...linkedinUrlGlobal\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_original on AssetInterface {\n    original_url: url\n    original_height: width\n    original_width: height\n  }\n"): (typeof documents)["\n  fragment imageSize_original on AssetInterface {\n    original_url: url\n    original_height: width\n    original_width: height\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_bannerCrop on AssetInterface {\n    bannerCrop_url: url @transform(transform: \"bannerCrop\")\n    bannerCrop_width: width @transform(transform: \"bannerCrop\")\n    bannerCrop_height: height @transform(transform: \"bannerCrop\")\n  }\n"): (typeof documents)["\n  fragment imageSize_bannerCrop on AssetInterface {\n    bannerCrop_url: url @transform(transform: \"bannerCrop\")\n    bannerCrop_width: width @transform(transform: \"bannerCrop\")\n    bannerCrop_height: height @transform(transform: \"bannerCrop\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_fullFit on AssetInterface {\n    fullFit_url: url @transform(transform: \"fullFit\")\n    fullFit_width: width @transform(transform: \"fullFit\")\n    fullFit_height: height @transform(transform: \"fullFit\")\n  }\n"): (typeof documents)["\n  fragment imageSize_fullFit on AssetInterface {\n    fullFit_url: url @transform(transform: \"fullFit\")\n    fullFit_width: width @transform(transform: \"fullFit\")\n    fullFit_height: height @transform(transform: \"fullFit\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_landscapeSmCrop on AssetInterface {\n    landscapeSmCrop_url: url @transform(transform: \"landscapeSmCrop\")\n    landscapeSmCrop_width: width @transform(transform: \"landscapeSmCrop\")\n    landscapeSmCrop_height: height @transform(transform: \"landscapeSmCrop\")\n  }\n"): (typeof documents)["\n  fragment imageSize_landscapeSmCrop on AssetInterface {\n    landscapeSmCrop_url: url @transform(transform: \"landscapeSmCrop\")\n    landscapeSmCrop_width: width @transform(transform: \"landscapeSmCrop\")\n    landscapeSmCrop_height: height @transform(transform: \"landscapeSmCrop\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_landscapeMdCrop on AssetInterface {\n    landscapeMdCrop_url: url @transform(transform: \"landscapeMdCrop\")\n    landscapeMdCrop_width: width @transform(transform: \"landscapeMdCrop\")\n    landscapeMdCrop_height: height @transform(transform: \"landscapeMdCrop\")\n  }\n"): (typeof documents)["\n  fragment imageSize_landscapeMdCrop on AssetInterface {\n    landscapeMdCrop_url: url @transform(transform: \"landscapeMdCrop\")\n    landscapeMdCrop_width: width @transform(transform: \"landscapeMdCrop\")\n    landscapeMdCrop_height: height @transform(transform: \"landscapeMdCrop\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_landscapeLgCrop on AssetInterface {\n    landscapeLgCrop_url: url @transform(transform: \"landscapeLgCrop\")\n    landscapeLgCrop_width: width @transform(transform: \"landscapeLgCrop\")\n    landscapeLgCrop_height: height @transform(transform: \"landscapeLgCrop\")\n  }\n"): (typeof documents)["\n  fragment imageSize_landscapeLgCrop on AssetInterface {\n    landscapeLgCrop_url: url @transform(transform: \"landscapeLgCrop\")\n    landscapeLgCrop_width: width @transform(transform: \"landscapeLgCrop\")\n    landscapeLgCrop_height: height @transform(transform: \"landscapeLgCrop\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_squareSmCrop on AssetInterface {\n    squareSmCrop_url: url @transform(transform: \"squareSmCrop\")\n    squareSmCrop_width: width @transform(transform: \"squareSmCrop\")\n    squareSmCrop_height: height @transform(transform: \"squareSmCrop\")\n  }\n"): (typeof documents)["\n  fragment imageSize_squareSmCrop on AssetInterface {\n    squareSmCrop_url: url @transform(transform: \"squareSmCrop\")\n    squareSmCrop_width: width @transform(transform: \"squareSmCrop\")\n    squareSmCrop_height: height @transform(transform: \"squareSmCrop\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_squareMdCrop on AssetInterface {\n    squareMdCrop_url: url @transform(transform: \"squareMdCrop\")\n    squareMdCrop_width: width @transform(transform: \"squareMdCrop\")\n    squareMdCrop_height: height @transform(transform: \"squareMdCrop\")\n  }\n"): (typeof documents)["\n  fragment imageSize_squareMdCrop on AssetInterface {\n    squareMdCrop_url: url @transform(transform: \"squareMdCrop\")\n    squareMdCrop_width: width @transform(transform: \"squareMdCrop\")\n    squareMdCrop_height: height @transform(transform: \"squareMdCrop\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_squareLgCrop on AssetInterface {\n    squareLgCrop_url: url @transform(transform: \"squareLgCrop\")\n    squareLgCrop_width: width @transform(transform: \"squareLgCrop\")\n    squareLgCrop_height: height @transform(transform: \"squareLgCrop\")\n  }\n"): (typeof documents)["\n  fragment imageSize_squareLgCrop on AssetInterface {\n    squareLgCrop_url: url @transform(transform: \"squareLgCrop\")\n    squareLgCrop_width: width @transform(transform: \"squareLgCrop\")\n    squareLgCrop_height: height @transform(transform: \"squareLgCrop\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_all on AssetInterface {\n    ...imageSize_original\n    ...imageSize_bannerCrop\n    ...imageSize_fullFit\n    ...imageSize_landscapeLgCrop\n    ...imageSize_landscapeMdCrop\n    ...imageSize_landscapeSmCrop\n    ...imageSize_squareLgCrop\n    ...imageSize_squareMdCrop\n    ...imageSize_squareSmCrop\n  }\n"): (typeof documents)["\n  fragment imageSize_all on AssetInterface {\n    ...imageSize_original\n    ...imageSize_bannerCrop\n    ...imageSize_fullFit\n    ...imageSize_landscapeLgCrop\n    ...imageSize_landscapeMdCrop\n    ...imageSize_landscapeSmCrop\n    ...imageSize_squareLgCrop\n    ...imageSize_squareMdCrop\n    ...imageSize_squareSmCrop\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_common on AssetInterface {\n    ...imageSize_original\n    ...imageSize_bannerCrop\n    ...imageSize_fullFit\n    ...imageSize_landscapeLgCrop\n    ...imageSize_landscapeMdCrop\n    ...imageSize_landscapeSmCrop\n    ...imageSize_squareLgCrop\n    ...imageSize_squareMdCrop\n    ...imageSize_squareSmCrop\n  }\n"): (typeof documents)["\n  fragment imageSize_common on AssetInterface {\n    ...imageSize_original\n    ...imageSize_bannerCrop\n    ...imageSize_fullFit\n    ...imageSize_landscapeLgCrop\n    ...imageSize_landscapeMdCrop\n    ...imageSize_landscapeSmCrop\n    ...imageSize_squareLgCrop\n    ...imageSize_squareMdCrop\n    ...imageSize_squareSmCrop\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageBase on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    url @transform(transform: \"fullFit\")\n    height @transform(transform: \"fullFit\")\n    width @transform(transform: \"fullFit\")\n  }\n"): (typeof documents)["\n  fragment imageBase on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    url @transform(transform: \"fullFit\")\n    height @transform(transform: \"fullFit\")\n    width @transform(transform: \"fullFit\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment image on AssetInterface {\n    ...imageBase\n    ...imageSize_common\n  }\n"): (typeof documents)["\n  fragment image on AssetInterface {\n    ...imageBase\n    ...imageSize_common\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldUrl on linkCta_ExternalLink_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldUrl on linkCta_ExternalLink_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldEntry on linkCta_InternalLink_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldEntry on linkCta_InternalLink_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldEmail on linkCta_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldEmail on linkCta_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldPhone on linkCta_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldPhone on linkCta_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n"): (typeof documents)["\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment navigationBase on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n        uri\n        language\n    } \n  }\n"): (typeof documents)["\n  fragment navigationBase on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n        uri\n        language\n    } \n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n"): (typeof documents)["\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n"): (typeof documents)["\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n"): (typeof documents)["\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n"): (typeof documents)["\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n"): (typeof documents)["\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n"): (typeof documents)["\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      ...seoTitle\n      ...seoJsonLd\n      ...seoMetaLinks\n      ...seoMetaTags\n      ...seoScripts\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      ...seoSiteVars\n    }\n  }\n"): (typeof documents)["\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      ...seoTitle\n      ...seoJsonLd\n      ...seoMetaLinks\n      ...seoMetaTags\n      ...seoScripts\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      ...seoSiteVars\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query allUris($section: [String], $type: [String], $orderBy: String) {\n    entries(section: $section, type: $type, orderBy: $orderBy) {\n      id\n      uri\n      slug\n    }\n  }\n"): (typeof documents)["\n  query allUris($section: [String], $type: [String], $orderBy: String) {\n    entries(section: $section, type: $type, orderBy: $orderBy) {\n      id\n      uri\n      slug\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy) {\n      ...entries\n    }\n  }\n"): (typeof documents)["\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy) {\n      ...entries\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query entryExists($uri: [String], $slug: [String], $id: [QueryArgument]) {\n    entry(uri: $uri, id: $id, slug: $slug, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n"): (typeof documents)["\n  query entryExists($uri: [String], $slug: [String], $id: [QueryArgument]) {\n    entry(uri: $uri, id: $id, slug: $slug, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $offset: Int\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, offset: $offset) {\n      ...entries\n    }\n  }\n"): (typeof documents)["\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $offset: Int\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, offset: $offset) {\n      ...entries\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query searchEntries(\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput]\n    ) {\n    entries(\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...entries\n    }\n    entryCount(\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n    )\n  }\n"): (typeof documents)["\n  query searchEntries(\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput]\n    ) {\n    entries(\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...entries\n    }\n    entryCount(\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query globals($site: [String] = \"default\") {\n    globalSets(site: $site) {\n      ...globals\n    }\n  }\n"): (typeof documents)["\n  query globals($site: [String] = \"default\") {\n    globalSets(site: $site) {\n      ...globals\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query navMenus {\n    primaryNavigationNodes: navigationNodes(navHandle: \"primaryNavigation\") {\n      ...navigationBase\n    }\n    footerNavigationNodes: navigationNodes(navHandle: \"footerNavigation\") {\n      ...navigationBase\n    }\n    extrasNavigationNodes: navigationNodes(navHandle: \"extrasNavigation\") {\n      ...navigationBase\n    }\n    specialNavigationNodes: navigationNodes(navHandle: \"specialNavigation\") {\n      ...navigationBase\n    }\n    ctaNavigationNodes: navigationNodes(navHandle: \"ctaNavigation\") {\n      ...navigationBase\n    }\n  }\n"): (typeof documents)["\n  query navMenus {\n    primaryNavigationNodes: navigationNodes(navHandle: \"primaryNavigation\") {\n      ...navigationBase\n    }\n    footerNavigationNodes: navigationNodes(navHandle: \"footerNavigation\") {\n      ...navigationBase\n    }\n    extrasNavigationNodes: navigationNodes(navHandle: \"extrasNavigation\") {\n      ...navigationBase\n    }\n    specialNavigationNodes: navigationNodes(navHandle: \"specialNavigation\") {\n      ...navigationBase\n    }\n    ctaNavigationNodes: navigationNodes(navHandle: \"ctaNavigation\") {\n      ...navigationBase\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query uriRedirect($site: String = \"default\", $uri: String) {\n    retour(site: $site, uri: $uri) {\n      redirectDestUrl\n      redirectHttpCode\n    }\n  }\n"): (typeof documents)["\n  query uriRedirect($site: String = \"default\", $uri: String) {\n    retour(site: $site, uri: $uri) {\n      redirectDestUrl\n      redirectHttpCode\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;