import Arrow from '@/components/Assets/Arrow';
import Chevron from '@/components/Assets/Chevron';
import ExternalLink from '@/components/Assets/ExternalLink';
import Cross from '@/components/Assets/Cross';
import { SVGType } from '@/lib/constants';
import * as styles from './Btn.Icon.css';
import classNames from 'classnames';
import Icon from '@/components/Icon';

export type BtnIcon = {
  type: string;
  hoverState?: boolean;
  stroke?: string;
} & SVGType;

export const BtnIcon = ({ type, hoverState, ...props }: BtnIcon) => {
  const holderClass = classNames('iconChild', styles.root({ hoverState }));

  switch (type) {
    case 'arrow':
      return <Arrow className={holderClass} {...props} />;
    case 'chevron':
      return <Chevron className={holderClass} {...props} />;
    case 'externalLink':
      return <ExternalLink className={holderClass} {...props} />;
    case 'cross':
      return <Cross className={holderClass} {...props} />;
    case 'shareLinkedin':
      return <Icon name="linkedin" />;
    case 'shareLink':
      return <Icon name="share" />;
    case 'shareFacebook':
      return <Icon name="facebook" />;
    case 'shareTwitter':
      return <Icon name="twitter" />;
  }
};
