import { FlexStyleProps, useFlexStyle } from './useFlexStyle';

import { Polymorphic } from '@/polymorphics/utils';
import Box, { BoxComponent, BoxProps } from '@/polymorphics/Box';

import React from 'react';

type FlexBaseProps = FlexStyleProps;

export type FlexProps<C extends React.ElementType = 'div', P = NoProps> = BoxProps<
  C,
  FlexBaseProps & P
>;

const Flex: BoxComponent<'div', FlexBaseProps> = React.forwardRef(function Flex<
  C extends React.ElementType
>(props: FlexProps<C>, ref?: Polymorphic.Ref<C>) {
  const boxProps = useFlexStyle(props);

  return <Box ref={ref} {...boxProps} />;
});

export default Flex;
