import React from 'react';
import { BoxStyleProps, useBoxStyle } from './useBoxStyle';
import { SprinklesProps, useSprinklesProps } from '@/style/utils/useSprinkles';
import { Polymorphic } from '@/polymorphics/utils';

export type BoxBaseProps = object & BoxStyleProps & SprinklesProps;

export type BoxProps<C extends React.ElementType = 'div', P = NoProps> = Polymorphic.PropsWithRef<
  C,
  BoxBaseProps & P
>;

export type BoxComponent<
  C extends React.ElementType = 'div',
  P = NoProps
> = Polymorphic.ForwardRefComponent<C, Polymorphic.OverrideProps<BoxBaseProps, P>>;

const Box: BoxComponent = React.forwardRef(function Box<C extends React.ElementType>(
  { as, sx: _sx, ...rest }: BoxProps<C>,
  ref?: Polymorphic.Ref<C>
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let props: React.ComponentPropsWithoutRef<any> = rest;

  // Apply style props
  props = useBoxStyle(props);

  // Apply sprinkles
  props = useSprinklesProps(props);

  const Component = as || 'div';

  return <Component ref={ref} {...props} />;
});

export default Box;
