import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyle%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA4XTW1LDIBQG4Peu4jzaMWEC5NLSN7fgApw0JQ2am4CtreO23IAbc8CoBeL0MV9%2B%2FhzIwOQwaHhbAMTxQz7WiZIJg%2FvPj6bncCe50NH0FIEqexUrLkW9ucxjdi1BriYoA4yyzLHUWFE4lhnDbi43RhwqDFGHVoZSh9b2ow6VtiuTvHN4y6Bqy268Ici8i6Aq2%2BoGo5RSim0cboEgQsjhuIyASt4tnYLqt8BZnuf5qpiWY4QxtsvJ9wRuw%2B6nAV%2BOkKD1fAdGRdjB7Yb9zdWzup%2FVxh5sEbiYP7dH%2B7tCf2KQoFVY0xoPuWNAk8ShnkHq0cAg82hkkHv0HJIMu5QZZO2NoQ3mHr4YpB4eDCbY06NVknn8atnXk1G%2F4cygH2RXtpvF%2B2I77E725tZDr%2BO67ER7%2Bu%2Bi2YgSZ%2B7%2BkFb0PG642Df677LZ7HHC6dxbrjWXsRrLSvT7iym%2BAGNL1WhABAAA%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyle%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA92Uy07DMBBF9%2FmKkbyhiyI7sePEXYUm%2BQ2Ulr6hKQXKS%2Fw7MgiJuUGyUFWJEK9ydTz2sUd2%2B7a9p9eIaDi8VK1Kd3IrHQmVa2niEcuVIyFjPdMNz2OfS13pgueJI2EubJJlPNeOxHhcxmPIjc%2BLMqsMz1NHopClxNw6EpWtkxrqZI5EUllZwn7yz33asuJ54%2BvElUlrnk8ciVrXmVE8n%2FrzMX7w%2FMqRsMYPns98nY9vFL1F7ocTnzs6NPuzb1MGvMQCgSkASwQkACsEMgDWoT1sEMgH3uf863f%2FH6Xu%2FoCUOloq5lLB9jM9vKmHkFTaQ6lDSCoJSeGKHSkEOlLYDB0pPJaOlOFSjyEpLHgCKWyG30tZLvUUksLeOIFUc7TUhEs9h6R0D1%2B%2Fl5AUrtiHm1ISrK5xwhwq3iCwAGCLwBKAFoEVADsE1gDcIrDxVu9ftV3FlAoAAA%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyle%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tcnp6MXhkMDogMTI7CiAgLS1yenoxeGQxOiAxNnB4OwogIC0tcnp6MXhkMjogMjRweDsKICAtLXJ6ejF4ZDM6IDI0cHg7Cn0%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyle%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA4XV246iQBAG4Pt9irp0skKobunGnofZIKCjAo0NeNrsu29kdda%2FnGSujP9XVRw0XS54P9DvH0RR1DenczomjliZpDu%2FP4XsKHn%2Brhyl1mCNdmRNhtnC0dKKWakjZiUKjaNjHmZR9Mt066QPyduzWqEMmglVoEuhGjQXugBdCU1BC6EGtBRqQSuhGeha6BJ0IzQH%2FRC6At0KLUB3QkvQvdAKtBa6Bm2EbkBboR%2BgXugWtBO6Az0I3YMGoTVoL7QBHYS2oKNQD3oU2oGehB5Az0ID6EVoD3oVOoByInhEZsFHZCX4hKwFn5EXgi%2FIqeArsnGUxGmoGkitI5ZZ5ohfK5eOlMxyR%2Bq1cuWoqPOmm%2BlQNXMq8rqYcbzUWrO6FdNPUrFS6nh6m5Oe%2BvFWi88BE95HJLH%2B12xiY%2BzU%2FNpaPlqfGqMkzowxmb1fnP9f3cb2dUb1mJHFOCV5fgS2sbXZNIXVFw%2BxFvuAN7cfQL28rc%2FDKFyvfC7x7OUtKp7qvJtG9pDtbxmnGNZfFDa3TGPW3rIFZn7642DWOVrlxX4T%2FNiWUeFrH%2BZ0%2F1j5UFbhEU73QlXeV9G2jfw4wJyDI9%2FlxXa4fFMYHA0hb%2Fu1D803pb188YOjhdjVoyOxXfnoiMW65pMjPa3mP38Bpy7LRgUIAAA%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fpolymorphics%2FTxt%2FTxt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA92Y0W6CMBSG732KczkTWFBn5%2FBhlgIHWkdb1tapW%2Fbuizi1TNPFyCTjkpyv5z9%2FW%2FEPico2YKxWsggg2T4k8DEAyJW04Qp5wWwMb1TfhaERq%2FV0uRrOB5%2BD%2Bw3yxWb9WLOpKpXeU7tCFACXDDW3w%2Fn5bjtuHDQeJ0O3%2B6zufqg151jXjQ96%2B%2BLzSI1IFUl21M2p4OWmuZocy4a%2FY7O4W1tyiSE7swNPdVlQXXAZJspaJZrAu2viqSMTvE0TtCMTizZNJB2ZeGnTRNqRibJNE1lHJoTfRObOiMfX4DmdR5%2BOvkAnv0Ln1a%2BDrk7h23Pj31TvEJV%2FCOoOwa4wqy4wy6%2FQkRfoLBqbenJXZy774mdzly39LHdZ4WeZy8qa%2FfEzje4JCgdSDhRD5FSqumJxbUOrqTS50iKGZVWhTqlBh3ytyRKtRR2aiqZcFlulUUNJH%2FvRkhcyhhSlRe0gxn%2BSxHVnfz%2F1A7s8TTnTyPVKgLrh5jvNzPfzZpgqTS1XMoalzFBvL0xzeczUG%2BrThHT2FAmwUR%2FiDgE27kPiIcAmfQg9BNhDH3IPATbtQ%2FQhwMg%2FSD8EfnvzthaACBhxowxEQHij561iEIGyuFESIvAdJv4%2BDG3%2FwgJg4wDYJAD2EACbBvvr3vY3gi%2BnSdhBuBAAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'yeijyx7',variantClassNames:{variant:{h1:'yeijyx8',h2:'yeijyx9',h3:'yeijyxa',h4:'yeijyxb',h5:'yeijyxc',h6:'yeijyxd',xs:'yeijyxe',sm:'yeijyxf',md:'yeijyxg',lg:'yeijyxh',xl:'yeijyxi'},color:{error:'yeijyxj',primary:'yeijyxk',primaryHighlights:'yeijyxl',primaryHeading:'yeijyxm'},autoMargin:{true:'yeijyxn'},noMargin:{true:'yeijyxo'},uppercase:{true:'yeijyxp'},letterSpacing:{'10%':'yeijyxq'},textAlign:{center:'yeijyxr'},font:{heading:'yeijyxs',body:'yeijyxt'},bold:{true:'yeijyxu'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['variant','color','autoMargin','noMargin','uppercase','letterSpacing','textAlign','font','bold']};
export var txtDefaultVars = {fontWeight:'var(--yeijyx3)',marginBottom:'var(--yeijyx4)'};
export var txtVars = {color:'var(--yeijyx0)',display:'var(--yeijyx1)',fontWeight:'var(--yeijyx2)'};
export var typographyClasses = {h1:{vars:{'var(--yeijyx3)':'var(--smwx5ux)','var(--yeijyx0)':'var(--_1o16p0nh)'},fontFamily:'var(--smwx5u6)',fontSize:'var(--smwx5uh)',lineHeight:'var(--smwx5u9)',marginBottom:'var(--smwx5uz)'},h2:{vars:{'var(--yeijyx3)':'var(--smwx5ux)','var(--yeijyx0)':'var(--_1o16p0nh)'},fontFamily:'var(--smwx5u6)',fontSize:'var(--smwx5ui)',lineHeight:'var(--smwx5u9)',marginBottom:'var(--smwx5uz)'},h3:{vars:{'var(--yeijyx3)':'var(--smwx5ux)','var(--yeijyx0)':'var(--_1o16p0nh)'},fontFamily:'var(--smwx5u6)',fontSize:'var(--smwx5uj)',lineHeight:'var(--smwx5u9)',marginBottom:'var(--smwx5uz)'},h4:{vars:{'var(--yeijyx3)':'var(--smwx5ux)','var(--yeijyx0)':'var(--_1o16p0nh)'},fontFamily:'var(--smwx5u6)',fontSize:'var(--smwx5uk)',lineHeight:'var(--smwx5u9)',marginBottom:'var(--smwx5uz)'},h5:{vars:{'var(--yeijyx3)':'var(--smwx5ux)','var(--yeijyx0)':'var(--_1o16p0nh)'},fontFamily:'var(--smwx5u6)',fontSize:'var(--smwx5ul)',lineHeight:'var(--smwx5u9)',marginBottom:'var(--smwx5uz)'},h6:{vars:{'var(--yeijyx3)':'var(--smwx5ux)','var(--yeijyx0)':'var(--_1o16p0nh)'},fontFamily:'var(--smwx5u6)',fontSize:'var(--smwx5um)',lineHeight:'var(--smwx5ud)'},xs:{fontFamily:'var(--smwx5u7)',fontSize:'var(--smwx5ur)',lineHeight:'var(--smwx5ud)'},sm:{fontFamily:'var(--smwx5u7)',fontSize:'var(--smwx5uq)',lineHeight:'var(--smwx5ue)'},md:{vars:{'var(--yeijyx3)':'var(--smwx5us)'},fontFamily:'var(--smwx5u7)',fontSize:'var(--smwx5up)',lineHeight:'var(--smwx5ua)'},lg:{fontFamily:'var(--smwx5u7)',fontSize:'var(--smwx5uo)',lineHeight:'var(--smwx5ue)'},xl:{fontFamily:'var(--smwx5u7)',fontSize:'var(--smwx5un)',lineHeight:'var(--smwx5ue)'}};
export var withHtml = 'yeijyx6';
export var withLede = 'yeijyx5';