import * as styles from './Btn.css';

import { RecipeVariants } from '@/style/utils/createRecipeObj';
import { createUseRecipeHook, PropsWithClassName } from '@/style/utils/createUseRecipeHook';

const useBtnStyleRecipe = createUseRecipeHook(styles.root);

export const useBtnStyle = <P extends PropsWithClassName<BtnStyleProps>>(props: P) => {
  return useBtnStyleRecipe(props);
};

export type BtnStyleProps = RecipeVariants<typeof styles.root>;

export { styles };
