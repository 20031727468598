import { SVG_SIZE, SVGType } from '@/lib/constants';
import React from 'react';

interface Chevron extends SVGType, React.HTMLAttributes<SVGElement> {
  stroke?: string;
}

export default function Chevron({ size, stroke, className }: Chevron) {
  return (
    <svg
      className={className}
      style={{
        height: SVG_SIZE[size].height,
        width: SVG_SIZE[size].width,
        transition: '200ms ease all',
      }}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        stroke={stroke}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80317 5.46967C3.09606 5.17678 3.57093 5.17678 3.86383 5.46967L8.00016 9.60601L12.1365 5.46967C12.4294 5.17678 12.9043 5.17678 13.1972 5.46967C13.4901 5.76256 13.4901 6.23744 13.1972 6.53033L8.53049 11.197C8.2376 11.4899 7.76273 11.4899 7.46983 11.197L2.80317 6.53033C2.51027 6.23744 2.51027 5.76256 2.80317 5.46967Z"
      />
    </svg>
  );
}
