import NextAnchor, { NextAnchorProps, NextAnchorPropsWithRef } from '@/polymorphics/NextAnchor';
import {
  combineComponentChildren,
  createDefaultsMap,
  Polymorphic,
  useDefaultsMap,
} from '@/polymorphics/utils';
import { SprinklesProps, useSprinklesProps } from '@/style/utils/useSprinkles';
import React from 'react';
import { BtnStyleProps, useBtnStyle } from './useBtnStyle';
import { BtnIcon } from './Btn.Icon';
import * as styles from './Btn.css';
import Box from '../Box';

export type BtnIconVariant = {
  iconType?: string;
  iconSize?: 'sm' | 'md' | 'lg';
  iconStroke?: string;
};

export type BtnBaseProps = BtnStyleProps &
  SprinklesProps &
  BtnIconVariant & { hoverState?: boolean };

export type BtnProps<
  C extends React.ElementType = 'button',
  P = NoProps
> = Polymorphic.PropsWithRef<C, BtnBaseProps & P>;

export interface BtnComponent<P = NoProps> {
  (props: Polymorphic.OverrideProps<BtnBaseProps & NextAnchorProps, P>): React.ReactNode;
  <C extends React.ElementType = 'button'>(
    props: Polymorphic.Props<C, Polymorphic.OverrideProps<BtnBaseProps, P>>
  ): React.ReactNode;
}

export interface BtnComponentWithRef<P = NoProps> {
  (props: Polymorphic.OverrideProps<BtnBaseProps & NextAnchorPropsWithRef, P>): React.ReactNode;
  <C extends React.ElementType = 'button'>(
    props: Polymorphic.PropsWithRef<C, Polymorphic.OverrideProps<BtnBaseProps, P>>
  ): React.ReactNode;
}

/**
 * A Map of HTML tag > props
 */
const buttonDefaultsMap = createDefaultsMap({
  button: {
    type: 'button',
    role: 'button',
  },
});

const BtnRoot: BtnComponentWithRef = React.forwardRef(function Btn<C extends React.ElementType>(
  { as, children, hoverState, iconSize, iconType, iconStroke, ...rest }: BtnProps<C>,
  ref?: Polymorphic.Ref<C>
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let props: React.ComponentPropsWithoutRef<any> = rest;

  // Apply Sprinkles props
  props = useSprinklesProps(props);

  // Apply style props
  props = useBtnStyle(props);

  // Target is external
  const isExternalLink = props?.target === '_blank';

  const isLink = as === 'a' || 'href' in props;

  /**
   * Force {@link NextAnchor} as anchor component
   */
  const Component = isLink ? NextAnchor : as || 'button';

  /**
   * Select any default props for the Component
   */
  const defaults = useDefaultsMap(buttonDefaultsMap, Component);

  props = {
    ...defaults,
    ...props,
  };

  return (
    <Component ref={ref} {...props}>
      <Box colored={false} className={styles.wrapper}>
        {children}
        {iconType && iconSize && (
          <BtnIcon
            stroke={iconStroke}
            hoverState={hoverState}
            type={isExternalLink ? 'externalLink' : iconType}
            size={iconSize}
          />
        )}
      </Box>
    </Component>
  );
});

const Btn = combineComponentChildren(BtnRoot, { Icon: BtnIcon });

export default Btn;
