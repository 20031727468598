import React from 'react';
import { useHtmlProps } from './useHtmlProps';
import { TxtStyleProps, useTxtStyle } from './useTxtStyle';
import { TruncateProps, useTruncateProps } from './useTxtTruncate';
import { SprinklesProps, useSprinklesProps } from '@/style/utils/useSprinkles';
import { Polymorphic } from '../utils';
import { useTxtVariantMap } from './useTxtVariantMap';

type TxtBaseProps = {
  /**
   * Parse any html in 'children' and force a 'span' as the render component
   */
  html?: boolean;

  // debug?: boolean;
} & TxtStyleProps &
  SprinklesProps &
  TruncateProps;

export type TxtProps<C extends React.ElementType = 'span'> = Polymorphic.PropsWithRef<
  C,
  TxtBaseProps
>;

const Txt: Polymorphic.ForwardRefComponent<'span', TxtBaseProps> = React.forwardRef(function Txt<
  C extends React.ElementType = 'span'
>({ as, html, ...rest }: TxtProps<C>, ref?: Polymorphic.Ref<C>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let props: React.ComponentPropsWithoutRef<any> = rest;

  // Apply Sprinkles
  props = useSprinklesProps(props);

  // Apply style props
  props = useTxtStyle(props);

  // Apply truncate props
  props = useTruncateProps(props).props;

  // Apply html props
  props = useHtmlProps(props, html);

  /**
   * Derive the default rendering component
   */
  const defaultComponent = useTxtVariantMap(rest.variant ?? 'md', as || 'span');

  /**
   * Set the rendering component
   * - Use the value of the "as" prop if supplied
   * - If outputting HTML, use 'span' as default component (avoids nesting 'p' tags)
   */
  const Component = as || (html ? 'span' : defaultComponent);

  return <Component ref={ref} {...props} />;
});

export default Txt;
