import { SVG_SIZE, SVGType } from '@/lib/constants';
import React from 'react';

interface ExternalLink extends SVGType, React.HTMLAttributes<SVGElement> {
  stroke?: string;
}

export default function ExternalLink({ size, stroke, className }: ExternalLink) {
  return (
    <svg
      className={className}
      style={{
        height: SVG_SIZE[size].height,
        width: SVG_SIZE[size].width,
        transition: '200ms ease all',
      }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        stroke={stroke}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10.75C20.4142 10.75 20.75 10.4142 20.75 10L20.75 4C20.75 3.58579 20.4142 3.25 20 3.25L14 3.25C13.5858 3.25 13.25 3.58579 13.25 4C13.25 4.41421 13.5858 4.75 14 4.75L18.1893 4.75L9.46967 13.4697C9.17678 13.7626 9.17678 14.2374 9.46967 14.5303C9.76256 14.8232 10.2374 14.8232 10.5303 14.5303L19.25 5.81066L19.25 10C19.25 10.4142 19.5858 10.75 20 10.75ZM17.25 18C17.25 18.6904 16.6904 19.25 16 19.25L6 19.25C5.30964 19.25 4.75 18.6904 4.75 18L4.75 8C4.75 7.30964 5.30964 6.75 6 6.75L10 6.75C10.4142 6.75 10.75 6.41421 10.75 6C10.75 5.58579 10.4142 5.25 10 5.25L6 5.25C4.48122 5.25 3.25 6.48122 3.25 8L3.25 18C3.25 19.5188 4.48122 20.75 6 20.75L16 20.75C17.5188 20.75 18.75 19.5188 18.75 18L18.75 14C18.75 13.5858 18.4142 13.25 18 13.25C17.5858 13.25 17.25 13.5858 17.25 14L17.25 18Z"
      />
    </svg>
  );
}
