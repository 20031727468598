import { colorThemeVars } from '@/style/partials/palette.css';
import * as styles from './Image.css';

export type ShortSVGTransform = {
  url?: string;
  id?: string;
  shadow?: boolean;
  flip?: boolean;
} & React.PropsWithChildren;

export const ShortSVGTransform = ({ url, id, shadow = true, flip = false }: ShortSVGTransform) => {
  if (!url) return null;

  return (
    <figure className={styles.figureShort({ shadow })}>
      {shadow && (
        <svg viewBox="0 0 252.85 117.4" className={styles.shadow({ type: 'short' })}>
          <path
            fill={colorThemeVars.primary.highlights || 'white'}
            d="M208.18,0H10.7A10.69,10.69,0,0,0,.62,14.26l34,96a10.71,10.71,0,0,0,10.08,7.13H242.15a10.69,10.69,0,0,0,10.08-14.26l-34-96A10.68,10.68,0,0,0,208.18,0Z"></path>
        </svg>
      )}
      <div className={styles.shortSvgTransform({ shadow })}>
        <svg width="100%" height="100%" fill="none" viewBox="0 0 252.85 117.4">
          <defs>
            <pattern
              className={styles.pattern({ flip })}
              id={'shortPattern-' + id}
              width="1"
              height="1"
              x="0"
              y="0">
              <image
                xlinkHref={url}
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid slice"></image>
            </pattern>
          </defs>
          <path
            className={styles.imagePath}
            style={{ transform: flip ? 'scale(-1, 1)' : '', transformOrigin: 'center' }}
            fill={`url(#shortPattern-${id})`}
            d="M208.18,0H10.7A10.69,10.69,0,0,0,.62,14.26l34,96a10.71,10.71,0,0,0,10.08,7.13H242.15a10.69,10.69,0,0,0,10.08-14.26l-34-96A10.68,10.68,0,0,0,208.18,0Z"></path>
        </svg>
      </div>
    </figure>
  );
};

export default ShortSVGTransform;
