import { vars } from '@/style/vars.css';
import { calc } from '@vanilla-extract/css-utils';

export const QUERY_FORWARD_ROUTE = '/api/pass';
export const POLL_INTERVAL = 2000;
export const I18N_DEFAULT_LOCALE = 'en';

export const IS_DEV = process.env.NODE_ENV === 'development';

export enum IMG_RATIO {
  SQUARE = 1,
  PORTRAIT = 1.2,
  LANDSCAPE = 496 / 744, // 0.715,
  LANDSCAPE_TALL = 0.8, // ~4:3
  LANDSCAPE_WIDE = 0.54, // 16:9
  VIDEO = 0.5625, // 16:9
}

export const ENTRY_TYPE_URI_SETTINGS = {
  articleIndex: 'news-insights',
} as const;

export const SVG_SIZE = {
  xl: {
    height: vars.space[4],
    width: vars.space[4],
  },
  lg: {
    height: vars.space[3],
    width: vars.space[3],
  },
  md: {
    height: calc.multiply(1.25, vars.space[2]),
    width: calc.multiply(1.25, vars.space[2]),
  },
  sm: {
    height: vars.space[1],
    width: vars.space[1],
  },
} as const;

export type SVGType = { size: keyof typeof SVG_SIZE };
