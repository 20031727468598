'use client';

import Shim, { ShimProps } from '@/components/Shim';
import { ImageEntry } from '@/lib/parse/images';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Sprinkles } from '@/style/sprinkles.css';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import * as styles from './Image.css';
import { ResponsiveImageTransform, useImageTransform } from './useImageTransform';
import TallSVGTransform from './TallSVGTransform';
import ShortSVGTransform from './ShortSVGTransform';
import { useNextHydrated } from '@/lib/helpers/createToggleStore';

export type ImageProps<T extends TagName = 'img'> = PropsOverride<
  NextImageProps,
  Partial<ImageEntry> & {
    ShimProps?: ShimProps<T>;
    ratio?: Sprinkles['ratio'];
    rounded?: boolean;
    tall?: boolean;
    short?: boolean;
    shadowSvg?: boolean;
    flip?: boolean;
    clipPath?: boolean;
    transform?: ResponsiveImageTransform;
  } & styles.ImageVariants
>;

const Image = <T extends TagName>(props: ImageProps<T>) => {
  const {
    ratio,
    ShimProps: maybeShimProps,
    fixedRatio: maybeFixedRatio,
    title,
    contain,
    rounded,
    round,
    tall = false,
    short = false,
    flip = false,
    shadowSvg = false,
    mimeType: _mimeType,
    noCrop: _noCrop,
    transform,
    transforms: _transforms,
    id,
    curved,
    ...rest
  } = props;

  const { url: src, ...responsiveValues } = useImageTransform(props, transform) ?? {};

  const hydrated = useNextHydrated();

  /**
   * Create the Shim
   */
  const ShimProps: ShimProps<T> | undefined =
    maybeShimProps || ratio || rounded || round
      ? (mergePropsClassName(
          { ratio, round, rounded, ...maybeShimProps },
          styles.shim
        ) as ShimProps<T>)
      : undefined;

  const fixedRatio = maybeFixedRatio ?? !!ShimProps;

  const img = !src ? (
    <></>
  ) : (
    <>
      <NextImage
        alt=""
        {...{
          'aria-label': title,
          ...mergePropsClassName(
            rest,
            styles.root({
              fixedRatio,
              contain,
              curved,
            })
          ),
          src,
          ...responsiveValues,
        }}
      />
    </>
  );

  if (ShimProps && hydrated) return <Shim {...ShimProps}>{img}</Shim>;

  if (tall) return <TallSVGTransform {...{ url: src, shadow: shadowSvg, id }} />;
  if (short) return <ShortSVGTransform {...{ url: src, shadow: shadowSvg, id, flip }} />;

  return img;
};

Image.styles = styles;

export default Image;
